import { fromJS, Map as IMap } from 'immutable';

import { giftCardFieldNames as gcKeys } from '../item/newItemForm';
import { itemHasAccessory, getItemAccessory, getItemCurrency } from '../item/itemSelectors';
import { OPTIONAL } from '../delivery/PhysicalDeliveryFormConstants';
import { feeTypes, getFeeAmountByTypeAndCost, getFeeTypeFromItem } from '../fees/feesUtils';
import { PAYMENT_PLUS_PAYMENT } from './paymentConstants';

export const filterAndMapKeys = (originData, keyMap) => (
    originData.filter((_, key) => keyMap.has(key)).mapKeys(
        originKey => keyMap.get(originKey, originKey)
    )
);


export const paymentKeyMap = fromJS({
    paymentMethod: 'paymentMethod',
    token: 'token',
    orderID: 'orderId',
    response: 'response',
    type: 'creditCardType',
    expYear: 'expirationYear',
    expMonth: 'expirationMonth',
    securityCode: 'securityCode',
    creditCard: 'creditCardNumber',
    payerID: 'payerId',
    firstName: 'purchaserFirstName',
    lastName: 'purchaserLastName',
    street1: 'street',
    street2: 'street2',
    street: 'street',
    houseNumberOrName: 'street2',
    city: 'city',
    state: 'state',
    postalCode: 'zipCode',
    countryCode: 'countryCode',
    phoneNumber: 'phone',
    senderEmail: 'purchaserEmail',
    masterpassOauthToken: 'masterpassOauthToken',
    checkoutId: 'checkoutId',
    cartId: 'cartId',
    paymentAuthReference: 'paymentAuthReference',
    paymentData: 'paymentData',
    stateOrProvince: 'state',
    emailAddress: 'purchaserEmail',
    phone: 'phone'
});

export const metaDataKeyMap = fromJS({
    amount: 'amount',
    currencyCode: 'currencyCode',
    brandCode: 'brandCode',
    loyaltyNumber: 'loyaltyNumber',
    senderOptIn: 'notifyOffers',
    promoCode: 'promoCode',
    locale: 'languageCode',
    discountAmount: 'totalDiscountRewardAmount',
    checkoutId: 'checkoutId',
    cartQualifies: 'cartQualifies',
    shippingPromoData: 'shippingPromoData'
    
});

export const giftCardKeyMap = fromJS({
    [gcKeys.MESSAGE_FIELD]: 'message',
    [gcKeys.QUANTITY_FIELD]: 'quantity',
    [gcKeys.AMOUNT_FIELD]: 'faceValue',
    [gcKeys.FACEPLATE_FIELD]: 'faceplateCode',
    [gcKeys.RECIPIENT_SEND_DATETIME_FIELD]: 'deliveryDate',
    [gcKeys.SENDER_NAME_FIELD]: 'senderName',
    [gcKeys.RECIPIENT_EMAIL_FIELD]: 'recipientEmail',
    [gcKeys.RECIPIENT_NAME_FIELD]: 'recipientName',
    [gcKeys.RECIPIENT_SMS_FIELD]: 'recipientSmsNumber',
    [gcKeys.IS_SELFBUY_FIELD]: 'selfBuy',
    [gcKeys.UYO_IMAGE_URL]: 'uyoImageUrl',
    [gcKeys.PROGRAM_CODE]: 'programCode',
    [gcKeys.EMAIL_FIELD_SELECTED]: 'checkedEmail',
    [gcKeys.SMS_FIELD_SELECTED]: 'checkedSms',
    [gcKeys.IS_SHARE_VIA_LINK]: 'isSharedViaLink'
});

export const shippingRecipientKeyMap = fromJS({
    fullName: 'fullName',
    street1: 'street',
    street2: 'street2',
    city: 'city',
    state: 'state',
    countryCode: 'countryCode',
    postalCode: 'zipCode',
    phoneNumber: 'phone'
});

export const responseKeyMap = fromJS({
    statement: 'statement',
    orderNumber: 'orderNumber',
    billingAddress: 'billingAddressFromOrderResponse'
});


export const sanitizedPaymentKeyMap = fromJS({
    fullName: 'fullName',
    firstName: 'firstName',
    lastName: 'lastName',
    street1: 'street1',
    street2: 'street2',
    city: 'city',
    state: 'state',
    postalCode: 'postalCode',
    countryCode: 'countryCode',
    phoneNumber: 'phoneNumber',
    paymentMethod: 'paymentMethod',
    purchaserEmail: 'purchaserEmail',
    senderEmail: 'senderEmail',
    purchaseDate: 'purchaseDate',
    currency: 'currency',
    emailAddress: 'senderEmail'
});


export const SecurePaymentServiceSetupKeyMap = fromJS({
    creditCard: 'cardNum',
    securityCode: 'cardCode',
    expMonth: 'expirationMonth',
    expYear: 'expirationYear',
    brandCode: 'brandCode',
    productCode: 'productCode',
    currency: 'currency',
    invoiceNumber: 'invoiceNumber',
    type: 'cardType',
    firstName: 'firstName',
    lastName: 'lastName',
    countryCode: 'countryCode',
    street1: 'street1',
    street2: 'street2',
    city: 'city',
    state: 'region',
    postalCode: 'postalCode',
    senderEmail: 'email',
    requestNum: 'requestNum'
});

export const payerAuthenticationCheckEnrollmentKeyMap = fromJS({
    creditCard: 'cardNum',
    securityCode: 'cardCode',
    expMonth: 'expirationMonth',
    expYear: 'expirationYear',
    brandCode: 'brandCode',
    productCode: 'productCode',
    type: 'cardType',
    currency: 'currency',
    firstName: 'firstName',
    lastName: 'lastName',
    countryCode: 'countryCode',
    street1: 'street1',
    street2: 'street2',
    city: 'city',
    state: 'region',
    postalCode: 'postalCode',
    senderEmail: 'email',
    phoneNumber: 'phoneNumber',
    referenceId: 'referenceId',
    amount: 'amount',
    requestNum: 'requestNum',
    returnUrl: 'returnUrl',
    giftCardCount: 'giftCardCount',
    httpBrowserLanguage: 'httpBrowserLanguage'
});



export const stripWhiteListedCharactersFromPhoneNumber = field => field.replace(/[-()+. ]/g, '');



export const paymentSerializer = (payment) => {
    const serializedPaymentData = filterAndMapKeys(payment, paymentKeyMap).asMutable();
    
    if (serializedPaymentData.get('phone')) {
        serializedPaymentData.update('phone', value => stripWhiteListedCharactersFromPhoneNumber(value));
    }
    return serializedPaymentData.asImmutable();
};


export const metaDataSerializer = (metaData) => {
    const serializedMeta = filterAndMapKeys(metaData, metaDataKeyMap);
    
    
    return serializedMeta.update('amount', val => (val ? val.toFixed(2) : undefined));
};

export const accessorySerializer = (accessory, currency) => (
    fromJS({
        code: accessory.get('code'),
        cost: accessory.getIn(['costs', currency, 'cost'])
    })
);



export const giftCardItemSerializer = (
    item,
    senderEmail,
    senderName,
    requireRecipientEmailForPhysicalGift,
    fees = [],
    senderPhone = undefined
) => {
    const orderCert = filterAndMapKeys(item, giftCardKeyMap).asMutable();
    const programCode = item.get('programCode');
    if (programCode == null) {
        
        
        orderCert.delete('programCode');
    }

    const amount = Number(item.get('amount'));

    
    orderCert.set('cartItemToken', item.get(gcKeys.ITEM_ID));

    
    if (item.get(gcKeys.IS_SELFBUY_FIELD)) {
        orderCert.set('recipientEmail', senderEmail);
        orderCert.set('senderName', senderName);
        orderCert.set('recipientName', senderName);
        if (senderPhone) {
            orderCert.set('recipientSmsNumber', senderPhone);
        }
    }
    const feeType = getFeeTypeFromItem(item);
    const feeAmount = getFeeAmountByTypeAndCost(fees, feeType, amount, programCode);
    if (item.get(gcKeys.IS_PLASTIC_FIELD)) {
        
        
        if (requireRecipientEmailForPhysicalGift === OPTIONAL
            && !item.get(gcKeys.RECIPIENT_PLASTIC_NOTIFICATION, false)) {
            orderCert.delete('recipientEmail');
        }
    }
    
    
    if (!item.get(gcKeys.IS_SELFBUY_FIELD) && item.get(gcKeys.EMAIL_FIELD_SELECTED) === false) {
        orderCert.delete('recipientEmail');
    }
    if (item.get(gcKeys.SMS_FIELD_SELECTED) === false) {
        orderCert.delete('recipientSmsNumber');
    }

    switch (feeType) {
        case feeTypes.UYOP: {
            orderCert.set('uyopFeeAmount', feeAmount);
            orderCert.set(
                'plasticActivationFeeAmount',
                getFeeAmountByTypeAndCost(fees, feeTypes.PLASTIC_ACTIVATION, amount, programCode)
            );
            break;
        }
        case feeTypes.PLASTIC_ACTIVATION: {
            orderCert.set('plasticActivationFeeAmount', feeAmount);
            break;
        }
        default:
            orderCert.set('digitalActivationFeeAmount', feeAmount);
    }

    
    if (orderCert.get('recipientSmsNumber')) {
        orderCert.update('recipientSmsNumber', value => stripWhiteListedCharactersFromPhoneNumber(value));
    }

    if (itemHasAccessory(item)) {
        orderCert.set('accessory', accessorySerializer(getItemAccessory(item), getItemCurrency(item)));
    }

    if (item.get('brandCode')) {
        orderCert.set('brandCode', item.get('brandCode'));
    }

    return orderCert.asImmutable();
};



export const shippingRecipientSerializer = (shippingRecipient) => {
    const shippingAddress = filterAndMapKeys(shippingRecipient, shippingRecipientKeyMap).asMutable();
    if (shippingAddress.get('phone')) {
        shippingAddress.update('phone', value => stripWhiteListedCharactersFromPhoneNumber(value));
    }
    return shippingAddress.asImmutable();
};


export const shippingGroupSerializer = (
    itemGroup,
    senderEmail,
    senderName,
    requireRecipientEmailForPhysicalGift,
    fees = [],
) => (
    IMap({
        shippingAddress: shippingRecipientSerializer(itemGroup.get('address')),
        certs: itemGroup.get('items').map(item => giftCardItemSerializer(
            item,
            senderEmail,
            senderName,
            requireRecipientEmailForPhysicalGift,
            fees
        )),
        shippingAmount: Number(itemGroup.get('shippingCost')).toFixed(2), 
        shippingMethodToken: itemGroup.getIn(['shippingMethod', 'token'])
    })
);


export const egiftCardsSerializer = (
    items,
    senderEmail,
    senderName,
    requireRecipientEmailForPhysicalGift,
    fees = {},
    senderPhone
) => (
    items.filterNot(
        item => item.get(gcKeys.IS_PLASTIC_FIELD)
    ).map(egc => giftCardItemSerializer(egc, senderEmail, senderName,
        requireRecipientEmailForPhysicalGift, fees, senderPhone))
);


export const getCustomRewardCardRecipient = (recipient, senderName = undefined, senderEmail = undefined) => ({
    fullName: recipient.get('selfBonus') ? senderName : `${recipient.get('recipient_name')}`,
    email: recipient.get('selfBonus') ? senderEmail : recipient.get('email')
});


export const orderSerializer = (
    payment,
    metaData,
    digitalItems,
    shippingGroups,
    requireRecipientEmailForPhysicalGift,
    customRewardCardRecipients = undefined,
    fees = [],
    cpfNumber = null,
    cartQualifies,
    shippingPromoData
) => {
    const loyaltyNumber = payment.get('loyaltyNumber', null);
    const capiUriVersion = payment.get('CAPI_URI_VERSION', null);
    const paymentData = paymentSerializer(payment);
    const senderEmail = paymentData.get('purchaserEmail');

    const senderName = `${paymentData.get('purchaserFirstName')} ${(paymentData.get('purchaserLastName') === 'LNU') ? '' : paymentData.get('purchaserLastName', '')}`;
    const paymentMethod = paymentData.get('paymentMethod');
    
    
    
    const addLoyaltyNumberToMetadata = meta => (loyaltyNumber ? meta.set('loyaltyNumber', loyaltyNumber) : meta);
    const addSenderSMSDelivery = metaData.get('senderSMSDelivery');
    const senderPhone = addSenderSMSDelivery ? paymentData.get('phone') : paymentData.get('phone');
    const egiftCards = egiftCardsSerializer(
        digitalItems,
        senderEmail,
        senderName,
        requireRecipientEmailForPhysicalGift,
        fees,
        senderPhone
    );
    if (paymentMethod !== PAYMENT_PLUS_PAYMENT || metaData.get('checkoutId') === undefined) {
        metaData.delete('checkoutId');
    }

    const plasticItems = shippingGroups.map(group => shippingGroupSerializer(
        group,
        senderEmail,
        senderName,
        requireRecipientEmailForPhysicalGift,
        fees
    )).toList();

    let serializedMetaData = metaDataSerializer(metaData.update(addLoyaltyNumberToMetadata))
        .merge({
            egiftCards,
            shippingGroups: plasticItems,
            cartQualifies,
            shippingPromoData
        });

    if (customRewardCardRecipients) {
        serializedMetaData = serializedMetaData.set(
            'customRewardCardRecipients',
            customRewardCardRecipients.map(
                recipient => getCustomRewardCardRecipient(fromJS(recipient), senderName, senderEmail)
            )
        );
    }
    serializedMetaData = serializedMetaData.set('CAPI_URI_VERSION', capiUriVersion);
    if (cpfNumber) {
        serializedMetaData = serializedMetaData.set('localCustomerId', cpfNumber);
    }

    if (capiUriVersion === 'v5') {
        return serializedMetaData.set(paymentMethod, paymentData.get('paymentData'));
    }

    return serializedMetaData.set(paymentMethod, paymentData.delete('paymentMethod'));
};


export const orderResponseSerializer = response => (
    filterAndMapKeys(fromJS(response), responseKeyMap)
);


export const sanitizePaymentData = paymentData => (
    filterAndMapKeys(fromJS(paymentData), sanitizedPaymentKeyMap)
);


export const payerAuthSerializer = authData => (
    filterAndMapKeys(fromJS(authData), SecurePaymentServiceSetupKeyMap)
);


export const payerAuthenticationCheckEnrollmentSerializer = data => (
    filterAndMapKeys(fromJS(data), payerAuthenticationCheckEnrollmentKeyMap)
);
