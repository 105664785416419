import { getProductDetailsPagePath } from '../routing/flow';
import { hasProductDetailsPageEnabled } from '../app/bootstrap';
import { STOREFRONT_FIXED, STOREFRONT_PDP_FLOW } from '../routing/flowConstants';

export const getHostname = () => {
    if (window && window.location && window.location.hostname) return window.location.hostname;
    return '';
};

export const getReferrer = () => {
    if (document && document.referrer) return document.referrer;
    return '';
};

export const getPath = () => {
    if (window && window.location && window.location.pathname) return window.location.pathname;
    return '';
};


export const getBuyFlow = () => {
    const pathName = getPath().toLowerCase();
    const productDetailPathName = getProductDetailsPagePath();
    return ((hasProductDetailsPageEnabled()
        && (pathName === productDetailPathName || pathName === `${productDetailPathName}/`))
        ? STOREFRONT_PDP_FLOW : STOREFRONT_FIXED);
};

export const getUrlParams = () => {
    
    
    let urlParams;
    if (window && window.location && typeof URLSearchParams !== 'undefined') {
        urlParams = new URLSearchParams(window.location.search);
    }

    const sessionDetails = {
        ref: '',
        promoCode: '',
        affiliateCode: ''
    };

    if (typeof urlParams !== 'undefined') {
        sessionDetails.ref = urlParams.get('ref') || '';
        sessionDetails.promoCode = urlParams.get('promo_code') || '';
        sessionDetails.affiliateCode = urlParams.get('affiliate_id') || '';
    }

    return sessionDetails;
};
