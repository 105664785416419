import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import React from 'react';
import { Container } from 'cstar-react-primitives/lib/redux-form/layout/Container';
import form from 'redux-form/lib/Form';
import PropTypes from 'prop-types';
import {
    newItemCountry,
    newItemHasRecipientPlasticNotification,
    newItemRecipientName
} from '../item/newItemForm';
import AddressFields from '../addressFields/AddressFields';
import {
    getRequireRecipientEmailForPhysicalGift
} from '../brand/brandSelectors';
import { ADDRESS_CONTEXT } from '../addressFields/addressFieldsModule';
import deliveryMessages from '../delivery/deliveryMessages';
import { OPTIONAL, REQUIRED } from '../delivery/PhysicalDeliveryFormConstants';
import RecipientEmailField from '../delivery/RecipientEmailField';

const PhysicalAddress = ({
    intl, countryCode, addressContext, requireRecipientEmailForPhysicalGift
}) => {
    const addressFieldProps = {
        addressContext,
        countryCode,
        cashbotName: 'delivery',
        doFocus: false,
        form,
        useAddressAutocomplete: true,
        usePreviousAddress: false,
        isPDP: true
    };

    const emailProps = {
        intl,
        recipientName: 'Recipient',
        isPlastic: true,
        cashbotName: 'delivery',
        requireRecipientEmailForPhysicalGift,
        spellCheckEmailPromptEnabled: true
    };

    const pageHeading = deliveryMessages.headerPlastic;
    const containerProps = {
        heading: intl.formatMessage(pageHeading),
        HeadingElement: 'h1'
    };

    return (
        <div className='container'>
            <Container {...containerProps} />
            <div className='address-fields-div'>
                <AddressFields {...addressFieldProps} />
                {requireRecipientEmailForPhysicalGift === REQUIRED
                    ? <RecipientEmailField {...emailProps} />
                    : null
                }
            </div>
        </div>
    );
};

PhysicalAddress.propTypes = {
    intl: intlShape.isRequired,
    addressContext: PropTypes.string,
    countryCode: PropTypes.string,
    hasPlasticNotification: PropTypes.bool,
    requireRecipientEmailForPhysicalGift: PropTypes.string,
    recipientName: PropTypes.string
};

PhysicalAddress.defaultProps = {
    addressContext: ADDRESS_CONTEXT.SHIPPING,
    hasPlasticNotification: false,
    countryCode: '',
    requireRecipientEmailForPhysicalGift: OPTIONAL,
    recipientName: ''
};

const mapStateToProps = state => ({
    recipientName: newItemRecipientName(state),
    hasPlasticNotification: newItemHasRecipientPlasticNotification(state),
    countryCode: newItemCountry(state),
    requireRecipientEmailForPhysicalGift: getRequireRecipientEmailForPhysicalGift(state)
});

export default connect(mapStateToProps)(injectIntl(PhysicalAddress));
