import React, { useEffect } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    getBrandUrl,
    getOrderingTokenServiceCodes
} from '../app/bootstrap';

import CashstarLinksCustom from './CashstarLinksCustom';
import CashstarLinksDefault from './CashstarLinksDefault';
import Logos from './Logos';
import LanguagePicker from '../intl/LanguagePicker';
import { getCustomCashstarLinks, getCardholderAgreementText } from './footerSelectors';
import FooterCardholderAgreement from './FooterCardholderAgreement';
import { getCardholderAgreementEnabled } from '../brand/brandSelectors';
import { ccmBannerExists } from '../cookie/cookieUtils';
import { initializeRiskified } from '../utils/riskifiedUtils';
import { getStorefrontSessionKey } from '../session/sessionSelectors';


export const Footer = ({
    customLinks,
    sessionKey,
    cardholderText,
    isCardholderAgreementEnabled
}) => {
    if (ccmBannerExists()) {
        return null;
    }

    useEffect(() => {
        const brandUrl = getBrandUrl();
        const ppRiskEnabled = getOrderingTokenServiceCodes()
            .split(',').includes('PAYMENT_PLUS_RISK');

        if (ppRiskEnabled && sessionKey && brandUrl) {
            
            
            initializeRiskified(sessionKey, brandUrl);
        }
    }, []);

    useEffect(() => {
        let cookieScript;
        if (window.cookieConsentEnabled) {
            cookieScript = document.createElement('script');
            cookieScript.src = '//consent.trustarc.com/notice?domain=nmi3gp&c=teconsent&js=nj&noticeType=bb&text=true&gtm=1';
            cookieScript.async = true;
            cookieScript.crossOrigin = 'anonymous';
            document.body.appendChild(cookieScript);
        }
        return () => {
            if (cookieScript) {
                document.body.removeChild(cookieScript);
            }
        };
    }, []);

    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-language">
                    <LanguagePicker openUpwards />
                </div>
                <div className="footer-row">
                    <div className="footer-links">
                        <nav className="footer-links__nav">
                            <ul>
                                {customLinks.size
                                    ? <CashstarLinksCustom customLinks={customLinks} />
                                    : <CashstarLinksDefault />}
                                <li><div id="teconsent" /></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="footer-row">
                    <Logos />
                </div>
                {cardholderText && isCardholderAgreementEnabled
                    && (
                        <FooterCardholderAgreement cardholderText={cardholderText} />
                    )
                }
            </div>
        </footer>
    );
};

Footer.defaultProps = {
    cardholderText: null,
    sessionKey: null,
    isCardholderAgreementEnabled: false

};

Footer.propTypes = {
    customLinks: ImmutablePropTypes.list.isRequired,
    cardholderText: PropTypes.string,
    sessionKey: PropTypes.string,
    isCardholderAgreementEnabled: PropTypes.bool
};


export const mapStateToProps = state => ({
    customLinks: getCustomCashstarLinks(state),
    cardholderText: getCardholderAgreementText(state),
    sessionKey: getStorefrontSessionKey(state),
    isCardholderAgreementEnabled: getCardholderAgreementEnabled(state)
});

export default connect(mapStateToProps)(Footer);
